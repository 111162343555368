import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useState } from "react";
import CommentDeleteDialog from "../../posts/commentDeleteDialog/commentDeleteDialog";

const PostCommentSingle = (props: {
  comment: any;
  index: number;
  deleteComment: (index: number) => void;
  onUserImgClick: (userId: string) => void;
}) => {
  const comment = props.comment;
  const [showDeleteAlert, setDeleteAlertVisible] = useState(false);

  function onCommentDelete() {
    props.deleteComment(props.index);
  }

  function confirmDelete() {
    setDeleteAlertVisible(true);
  }

  return (
    <ListItem
      key={comment.loci_commentID}
      alignItems='flex-start'
      sx={{ 
        paddingLeft: 0,
        "& .MuiListItemSecondaryAction-root": {
          top: "10px",
          transform: "translateY(0%)"
        }
      }}
      secondaryAction={
        comment.my != "n" ? (
          <IconButton edge='end' aria-label='delete'  onClick={confirmDelete}>
            <DeleteIcon sx={{ width:"20px" }} />
          </IconButton>
        ) : null
      }
    >
      <ListItemAvatar>
        <Avatar
          variant='rounded'
          alt={comment.USE_firstname + " " + comment.USE_surename}
          sx={{width:"35px!important", height:"35px!important", borderRadius:"12px"}}
          src={
            comment.USE_pic
              ? comment.USE_pic
              : comment.LCU_fbpic
                ? comment.LCU_fbpic
                : "/images/profile.png"
          }
        />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography component="span" variant="body1" style={{ display: "block", fontSize: "0.875rem" }}>
              {comment.USE_firstname + " " + comment.USE_surename}
            </Typography>
            <Typography component="span" variant="body2" style={{ display: "block", fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.6)" }}>
              {comment.LOC_date}
            </Typography>
          </>
        }
        secondary={comment.LOC_text}
        secondaryTypographyProps={{ style: { textAlign: "left", margin: "10px 0 0", wordWrap: "break-word" } }} 
      />
      {showDeleteAlert && (
        <CommentDeleteDialog
          deleting={true}
          handleClose={() => setDeleteAlertVisible(false)}
          onSubmit={() => {
            onCommentDelete();
          }}
        />
      )}
    </ListItem>
  );
};

export default PostCommentSingle;
