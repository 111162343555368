import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "lib/utils/Commons";
import locciIcon from "../../assets/newsIcon.png";


const GroupCard = (props) => {
  const history = useNavigate();

const { t, i18n } = useTranslation();

const getGroupType=(group)=> {

    if (group?.community === "y") {
      return t("community_feed")
    }
    else if (group?.public === "y" || group.LGP_public==="y") {
      return t("public_grp")
    }
    else if (group?.public === "n" || group.LGP_public==="n") {
      return t("private_grp")
    }
    else {
      return t("public_feed_title")
    }



  }

    const {group}=props
    return (
        <>
            <div className={group.name? "group-card cursor":'group-card'} onClick={props.onCardClick}>
                <div className="single-group">
                    <div className="single-group-header">
                        <img
                            src={
                                  group.id===0?locciIcon :group.userpic || group.UCT_avatar ?group.userpic || group.UCT_avatar: "/images/profile.png"
                            }
                        />
                        <div>

                            <span
                                className={group.name? "bold cursor":'bold'}
                               
                            >
                                {group.name || group?.LGP_name}
                            </span>

                            <div style={{ fontSize: 12 }}>
                                {getGroupType(group)}

                            </div>
                        </div>

                    </div>
                    <div className="single-group-actions">
                        <div className="single-group-details">
                            <div className="d-flex flex-column">
                                {group.id !== 0 && <span>

                                    {dateFormatter(group.created)}
                                </span>}

                           
                                {group.id !== 0  && <>
                                    {group.superAdmin == "1" && (
                                        <span>{t("administrator")}</span>
                                    )}
                                    {group.admin == "1" && group.superAdmin != "1" && (
                                        <span>{t("moderator")}</span>
                                    )}
                                    {group.admin != "1" && group.superAdmin != "1" && (
                                        <span>{t("my_groups_member")}</span>
                                    )}

                                </>}
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default GroupCard