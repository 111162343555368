import TwitterTextView from "components/commons/TwitterTextView";
import { Image } from "components/shared/image";
import ReactHtmlParser from "html-react-parser";
import { PostData } from "lib/types/postTypes";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import PostActions from "./PostActions";
import PostContextMenu from "./PostContextMenu";
import PostDocuments from "./PostDocuments";
import PostMedia from "./PostMedia";
import * as Styled from "./style";
import IconButton from "../../elements/buttons/IconButton";

type SinglePostProps = {
  token: string;
  post: PostData;
  isMember: boolean;
  showGroupLink?: boolean;
  analysePostAction: (postID: number) => void;
  sharePostAction: (postID: number) => void;
  editPostAction: (post: any) => void;
  deletePostAction: (postID: number) => void;
  reportPostAction: (postId: number, message: string) => void;
  userDetailsAction: (id: number, post?: any) => void;
  groupInfoAction: (id: string) => void;
};

const SinglePost = (props: SinglePostProps) => {
  const history = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { post } = props; 
  
  const [authorName, setAuthorName] = useState<string>("");
  const regex = /(<([^>]+)>)/gi;
  const styles = {
    linkStyle: {
      color: "#95b83b",
      cursor: "pointer",
    },
    hashtagStyle: {
      color: "#95b83b",
      cursor: "pointer",
    },
  };

  useEffect(() => {
    if (props.post) {
      setAuthorName(props.post.USE_firstname + " " + props.post.USE_surename);
    }
  }, [props.post]);

  const onPressLink = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, url: string) => {
    e.preventDefault();
    window.open(url, "_blank");
  };

  const onHashTagClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
    e.preventDefault();
    // eslint-disable-next-line no-console
    console.log(value);
  };

  const onAuthorClick = () => {
    if (post.OrgGroupId !== null && post.OrgGroupId !== "") {
      props.groupInfoAction(post.OrgGroupId as string);
    } else {
      props.userDetailsAction(post.FK_userID as any, post);
    }
  };  

  const handleCallToActionClick = () => {
    if (post?.LCP_cta && post.LCP_cta.LPC_action === "mailto") {
      const mailtoLink = `mailto:${post?.LCP_cta?.LPC_link}?subject=${encodeURIComponent(post?.LCP_cta?.LPC_mail_subject)}&body=${encodeURIComponent(post?.LCP_cta?.LPC_mail_text)}`;
      window.location.href = mailtoLink;
    } else if (post?.LCP_cta && post.LCP_cta.LPC_link) { // Check if link exists
      const redirectLink = post?.LCP_cta?.LPC_link;
      window.open(redirectLink, "_blank"); // Open link in a new window
    }
  };

  return (
    <>
      <Styled.PostHeader>
        <Styled.PostAuthor>
          <Image
            src={post.USE_pic}
            alt={authorName}
            onClick={onAuthorClick}
            placeholder='/images/profile.png'
          />
          <div className='author-name-address'>
            <a onClick={onAuthorClick} className='author-name'>
              {authorName}
            </a>
            <Styled.LightText>
              {post.USE_plz}, {post.STA_name}
            </Styled.LightText>
          </div>
        </Styled.PostAuthor>
        {post.LCP_mayor === "y" && location.pathname !== "/" && (
          <div className='premium-badge'>
            <i className='material-icons purple'>star</i>
          </div>
        )}
        <PostContextMenu
          data={props.post}
          isMember={props.isMember}
          token={props.token}
          analysePostAction={props.analysePostAction}
          sharePostAction={props.sharePostAction}
          editPostAction={props.editPostAction}
          deletePostAction={props.deletePostAction}
          reportPostAction={props.reportPostAction}
        />
      </Styled.PostHeader>
      <div className='mb-3'>
        <Styled.LightText>{post.LCP_date}</Styled.LightText>
        {props.showGroupLink && (
          <Styled.LightText>
            {" "}
            |{" "}
            {post.UCT_name !== null ? (
              <Styled.CategoryLink
                className='postOf cursor'
                onClick={() => history(`/group/${post.FK_user_categoryID}`)}
              >
                {post.UCT_name}
              </Styled.CategoryLink>
            ) : (
              <Styled.CategoryLink
                className='postOf cursor'
                onClick={() => history("/public-feed")}
              >
                {t("regional_feed_title")}
              </Styled.CategoryLink>
            )}
          </Styled.LightText>
        )}
        <Styled.LightText></Styled.LightText>
      </div>
      <Styled.Heading>{post.LCP_subject}</Styled.Heading>
      <Styled.Content>
        {post?.LCP_mess && post.LCP_mess.includes("<div") ? (
          <Styled.HTMLParser>{ReactHtmlParser(post.LCP_mess)}</Styled.HTMLParser>
        ) : (
          post.LCP_mess ? (
            <TwitterTextView
              className='mb-3'
              style={null}
              hashtagStyle={styles.hashtagStyle}
              mentionStyle={null}
              linkStyle={styles.linkStyle}
              emailStyle={null}
              onPressLink={onPressLink}
              onPressHashtag={onHashTagClick}
              extractHashtags={undefined}
              extractMentions={undefined}
              onPressMention={undefined}
              extractLinks={undefined}
              extractEmails={undefined}
              onPressEmail={undefined}
            >
              {post.LCP_mess.replace(regex, "")}
            </TwitterTextView>
          ) : (
            <div className="mb-3"></div>
          )
        )}    

        
{post.LCP_cta !== null && ( <div className="form-group" style={{ paddingTop: "10px" }} >
                    <IconButton
                     outlined={true} 
                     text={post?.LCP_cta?.LPC_title}
                     iconRight={true}  
                     handleClick={()=>handleCallToActionClick()}
                     className="w-100 btn-secondary justify-content-center" 
                    />
                  </div> ) }
        
        {post.LCP_media?.length > 0 && <PostMedia media={post.LCP_media} />}
        {post.LCP_docs?.length > 0 && (
          <PostDocuments token={props.token} documents={post.LCP_docs} />
        )}
        <PostActions token={props.token} post={post} isMember={props.isMember} />
      </Styled.Content>
    </>
  );
};

export default SinglePost;
