import { Card } from "components/shared/card";
import ReactHtmlParser from "html-react-parser";
import { GroupSettingsData } from "lib/types/groupTypes";
import linkifyHtml from "linkify-html";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoTabFiles } from "./InfoTabFiles";
import * as Styled from "./style";

type Props = {
  data: GroupSettingsData | null;
};

export const InfoTab = ({ data }: Props) => {
  const { t } = useTranslation();
  const [website, setWebsite] = useState<string>("");

  useEffect(() => {
    if (data?.LGP_web) {
      if (data.LGP_web.startsWith("http://") || data.LGP_web.startsWith("https://")) {
        setWebsite(data.LGP_web);
      } else {
        setWebsite("https://" + data.LGP_web);
      }
    }
  }, [data?.LGP_web]);

  return (
    <>
      <Card cutTop>
        <Styled.CardHeading>{t("group.info-heading")}</Styled.CardHeading>
        <p>
          {ReactHtmlParser(
            linkifyHtml(data?.LGP_about ? data?.LGP_about : "n.a.", {
              nl2br: true,
              target: {
                url: "_blank",
              },
            }),
          )}
        </p>
        <Styled.CardDevider />
        <Styled.CardHeading>{t("group.contact-heading")}</Styled.CardHeading>
        <Styled.InfoContactList>
          <Styled.InfoContactListItem
            className={data?.LGP_contact ? "" : "disabled"}
            href={"mailto:" + data?.LGP_contact}
          >
            <span>
              <i className='material-icons material-symbols-outlined'>mail</i>
            </span>
            {data?.LGP_contact || t("common.not-set")}
          </Styled.InfoContactListItem>
          <Styled.InfoContactListItem
            className={data?.LGP_phone ? "" : "disabled"}
            href={"tel:" + data?.LGP_phone}
          >
            <span>
              <i className='material-icons material-symbols-outlined'>call</i>
            </span>
            {data?.LGP_phone || t("common.not-set")}
          </Styled.InfoContactListItem>
          <Styled.InfoContactListItem
            className={website ? "" : "disabled"}
            href={website || "#"}
            target='_blank'
          >
            <span>
              <i className='material-icons material-symbols-outlined'>public</i>
            </span>
            {data?.LGP_web || t("common.not-set")}
          </Styled.InfoContactListItem>
        </Styled.InfoContactList>
      </Card>
      <Card>
        <InfoTabFiles data={data} />
      </Card>
    </>
  );
};
