import AttachmentIcon from "@mui/icons-material/Attachment";
import DownloadIcon from "@mui/icons-material/Download";
import { Chip } from "@mui/material";
import { downloadFromUnauthURL } from "api/groups";
import Lightbox from "components/elements/lightbox";
import { HandleEmptyState } from "components/shared/handle-empty-state";
import { Image } from "components/shared/image";
import { Modal } from "components/shared/modal";
import { GroupSettingsData } from "lib/types/groupTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Styled from "./styled";

enum TABS {
  IMAGES = "images",
  VIDEOS = "videos",
  DOCUMENTS = "documents",
}

type Props = {
  data: GroupSettingsData | null;
};

export const InfoTabFiles = ({ data }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<TABS>(TABS.IMAGES);
  const [modalActiveTab, setModalActiveTab] = useState<TABS>(TABS.IMAGES);

  const handleDownloadClick = (id: number, name: string) => {
    downloadFromUnauthURL(null, {
      id,
      name,
    });
  };

  const handleLightboxItemOpen = (index: number) => {
    setLightboxIndex(index);
  };

  const handleSeeAllClick = () => {
    setIsOpen(true);
    setModalActiveTab(activeTab);
  };

  const documents =
    data?.LGP_media?.filter((media) => media.mime_type.includes("application")) ?? [];
  const videos = data?.LGP_media?.filter((media) => media.mime_type.includes("video")) ?? [];
  const images =
    data?.LGP_media?.filter((media) => media.mime_type.includes("image")).map((media) => ({
      ...media,
      type: "p",
    })) ?? [];

  return (
    <>
      <Styled.InfoTabFiles>
        <Styled.InfoTabFilesHeader>
          <Styled.InfoTabFilesHeaderItems>
            {Object.values(TABS).map((tab) => (
              <Styled.InfoTabFilesHeaderItem
                key={tab}
                $active={activeTab === tab}
                onClick={() => setActiveTab(tab)}
              >
                {t(`group.gallery-tab-${tab}`)}
              </Styled.InfoTabFilesHeaderItem>
            ))}
          </Styled.InfoTabFilesHeaderItems>
          <Styled.InfoTabFilesSeeAll onClick={handleSeeAllClick}>
            {t("group.gallery-see-all")}
            <Styled.LinkArrowIcon />
          </Styled.InfoTabFilesSeeAll>
        </Styled.InfoTabFilesHeader>
        <Styled.InfoTabFilesContent $isExtended={activeTab !== TABS.DOCUMENTS}>
          <Styled.InfoTabFilesWrapper>
            {activeTab === TABS.IMAGES && (
              <HandleEmptyState isEmpty={!images || images?.length === 0}>
                {images?.map(({ id, img, name }, index) => (
                  <Styled.InfoTabFilesItem
                    key={`image-${id}`}
                    onClick={() => handleLightboxItemOpen(index)}
                  >
                    <Styled.InfoTabFilesItemThumb>
                      <Image src={img} alt={name} />
                    </Styled.InfoTabFilesItemThumb>
                  </Styled.InfoTabFilesItem>
                ))}
              </HandleEmptyState>
            )}
            {activeTab === TABS.VIDEOS && (
              <HandleEmptyState isEmpty={!videos || videos?.length === 0}>
                {videos?.map(({ id, thumb, name, date }, index) => (
                  <Styled.InfoTabFilesItem
                    key={`video-${id}`}
                    $fixedWidthResponsive
                    onClick={() => handleLightboxItemOpen(index)}
                  >
                    <Styled.InfoTabFilesItemThumb $small>
                      <Image src={thumb} alt={name} />
                    </Styled.InfoTabFilesItemThumb>
                    <Styled.InfoTabFilesItemContent>
                      <h4>{name}</h4>
                      <div>
                        <span>{date}</span>
                      </div>
                    </Styled.InfoTabFilesItemContent>
                  </Styled.InfoTabFilesItem>
                ))}
              </HandleEmptyState>
            )}
            {activeTab === TABS.DOCUMENTS && (
              <HandleEmptyState isEmpty={!documents || documents?.length === 0}>
                <Styled.InfoTabDocumentsWrapper>
                  {documents.slice(0, 5).map(({ id, name }) => (
                    <Styled.InfoTabFilesItem key={`document-${id}`}>
                      <Chip
                        deleteIcon={<DownloadIcon />}
                        onClick={() => {
                          handleDownloadClick(id, name);
                        }}
                        onDelete={() => {
                          handleDownloadClick(id, name);
                        }}
                        icon={<AttachmentIcon />}
                        label={name}
                      />
                    </Styled.InfoTabFilesItem>
                  ))}
                </Styled.InfoTabDocumentsWrapper>
              </HandleEmptyState>
            )}
          </Styled.InfoTabFilesWrapper>
        </Styled.InfoTabFilesContent>
      </Styled.InfoTabFiles>
      <Modal isOpen={isOpen} rounded onClose={() => setIsOpen(false)}>
        <Styled.InfoTabFiles>
          <Styled.InfoTabFilesHeader>
            <Styled.InfoTabFilesHeaderItems>
              {Object.values(TABS).map((tab) => (
                <Styled.InfoTabFilesHeaderItem
                  key={tab}
                  $active={modalActiveTab === tab}
                  onClick={() => setModalActiveTab(tab)}
                >
                  {t(`group.gallery-tab-${tab}`)}
                </Styled.InfoTabFilesHeaderItem>
              ))}
            </Styled.InfoTabFilesHeaderItems>
          </Styled.InfoTabFilesHeader>
          <Styled.InfoTabModalGalleryContent>
            {modalActiveTab === TABS.IMAGES && (
              <HandleEmptyState isEmpty={!images || images?.length === 0}>
                <Styled.InfoTabModalGalleryWrapper>
                  {images?.map(({ id, img, name }, index) => (
                    <Styled.InfoTabModalGalleryItem
                      key={`image-modal-${id}`}
                      onClick={() => handleLightboxItemOpen(index)}
                    >
                      <Image src={img} alt={name} />
                    </Styled.InfoTabModalGalleryItem>
                  ))}
                </Styled.InfoTabModalGalleryWrapper>
              </HandleEmptyState>
            )}
            {modalActiveTab === TABS.VIDEOS && (
              <HandleEmptyState isEmpty={!videos || videos?.length === 0}>
                <Styled.InfoTabModalVideosWrapper>
                  {videos?.map(({ id, thumb, name, date }, index) => (
                    <Styled.InfoTabFilesItem
                      key={`video-modal-${id}`}
                      onClick={() => handleLightboxItemOpen(index)}
                    >
                      <Styled.InfoTabFilesItemThumb $small $fullWidth>
                        <Image src={thumb} alt={name} />
                      </Styled.InfoTabFilesItemThumb>
                      <Styled.InfoTabFilesItemContent>
                        <h4>{name}</h4>
                        <div>
                          <span>{date}</span>
                        </div>
                      </Styled.InfoTabFilesItemContent>
                    </Styled.InfoTabFilesItem>
                  ))}
                </Styled.InfoTabModalVideosWrapper>
              </HandleEmptyState>
            )}
            {modalActiveTab === TABS.DOCUMENTS && (
              <HandleEmptyState isEmpty={!documents || documents?.length === 0}>
                <Styled.InfoTabDocumentsWrapper>
                  {documents?.map(({ id, name }) => (
                    <Styled.InfoTabFilesItem key={`document-${id}`}>
                      <Chip
                        deleteIcon={<DownloadIcon />}
                        onClick={() => {
                          handleDownloadClick(id, name);
                        }}
                        onDelete={() => {
                          handleDownloadClick(id, name);
                        }}
                        icon={<AttachmentIcon />}
                        label={name}
                      />
                    </Styled.InfoTabFilesItem>
                  ))}
                </Styled.InfoTabDocumentsWrapper>
              </HandleEmptyState>
            )}
          </Styled.InfoTabModalGalleryContent>
        </Styled.InfoTabFiles>
      </Modal>
      {lightboxIndex !== null && (
        <Lightbox
          onClose={() => setLightboxIndex(null)}
          media={(isOpen ? modalActiveTab : activeTab) === TABS.IMAGES ? images : videos}
          index={lightboxIndex}
          setIndex={(i: number) => setLightboxIndex(i)}
          nameDate={undefined}
        />
      )}
    </>
  );
};
