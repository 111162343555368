import React, { Component } from "react";
import Button from "../elements/buttons/Button";
import "rc-slider/assets/index.css";
import "./Groups.scss";
import { getMyGroups, leaveGroup, getMyDefaultGroup } from "../../api/groups";
import IconLink from "../elements/buttons/IconLink";
import EditGroup from "./edit-group";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { mapStateToProps } from "lib/store/mapStateToProps";
import { withTranslation } from "react-i18next";
import { withRouter } from "../customNavigate/withRouter";
import { dateFormatter } from "lib/utils/Commons";
import ShowInfoModal from "../elements/custom-modal/ShowInfoModal";
import locciIcon from "../../assets/newsIcon.png";

// import {getMyGroups} from "../..cindex"

class MyGroups extends Component {
  state = {
    editing: false,
    query: "",
    noDataTitle: "",
    groups: [],
    defaultGrpId: null,
    showOptionsDD: false,
    showInfoModal: false,
  };

  constructor(props) {
    super(props);
    this.minDistance = 0;
    this.maxDistance = 2000;
    this.state = { isLoading: true };
  }

  componentDidMount() {
    console.log("prop", this.props.token);
    this.getMyGroups();
    this.getMyDefaultGroup();
    this.setState({ isLoading: false });

  }


  getMyGroups() {
    console.log("prop update", this.props.query);

    getMyGroups(this.props.token, this.props.query).then((res) => {
      if (res.data.OK) {
        const data = res.data.grp;
        console.log(res,"aaaa");

        if (this.props.query === "") {
          if (res.data.show_regional == 'y'){

          let modifiedGroups = [
            {
              id: "public",
              name: "Regional Feed",
              userid: "public",
              admin: "public",
              superAdmin: "public",
              userpic: locciIcon,
              allow_post: "public",
              promo: "public",
              UCM_IsPersonalized: "public",
              community: "public",
              created: "2023-02-17 08:30:17",
            },
            ...res.data.grp,
          ];
          modifiedGroups.flat();
          this.setState({ groups: modifiedGroups });

          } else {
                this.setState({ groups: data });
          }
        } else {
          this.setState({ groups: data });
        }
        if (this.state.groups?.length < 1) {
          this.setState({ noDataTitle: "no-group-found" });
        }
      }
    });
  }

  getMyDefaultGroup() {
    getMyDefaultGroup(this.props.token).then((res) => {
      if (res.data.OK) {
        console.log(res.data.data);
        this.setState({ defaultGrpId: res.data.data });
      }
    });
  }

  onLeaveGrpClick(grpId) {
    leaveGroup(this.props.token, grpId).then((res) => this.getMyGroups());
  }

  componentDidUpdate() {
    console.log("props", this.props.query);
    if (this.state.query != this.props.query) {
      this.setState({ query: this.props.query });
      this.getMyGroups();
    }
  }

  getGroupType(group) {
    const { t } = this.props;

    if (group?.community === "y") {
      return t("community_feed");
    } else if (group?.public === "y") {
      return t("public_grp");
    } else if (group?.public === "n") {
      return t("private_grp");
    } else {
      return t("public_feed_title");
    }
  }

  navigateToPage(group) {
    const { t } = this.props;
    console.log("admin", group, group.admin, group.superAdmin);
    if (group.id === "public") return this.props.history.push("/settings");
    else if (group.superAdmin == "1") {
      return this.setState({
        editing: true,
        groupId: group.id,
      });
    } else {
      return this.props.history.push("/group-settings/" + group.id);
    }
  }

  render() {
    const { t } = this.props;
    const publiGroup = {
      name: t("regional_feed_title"),
      id: "public",
      userpic: locciIcon,
    };

    return (
      <>
        <div className="my-groups main-content">
          <div className="main-content-narrow reset-maxwidth">
            {/* <div className="newPostBtnHolder">
            <IconButton
              icon="add"
              text={t("add_edit_group_create")}
              type="button"
              handleClick={() => this.setState({ editing: true })}
            />
          </div> */}

            <div className="groups-listing">
              {this.state.groups?.length > 0 ? (
                this.state.groups.map((group, i) => (
                  <div className="single-group" key={i}>
                    <div className="single-group-header">
                      <img
                        src={
                          group.userpic ? group.userpic : "/images/profile.png"
                        }
                      />
                      <div>
                        <span
                          className="bold cursor"
                          onClick={() =>
                            group.id === "public"
                              ? this.props.history.push(`/public-feed`)
                              : this.props.history.push(`/group/${group.id}`)
                          }
                        >
                          {group.name}
                        </span>

                        <div style={{ fontSize: 12 }}>
                          {this.getGroupType(group)}
                        </div>
                      </div>
                    </div>
                    <div className="single-group-actions">
                      <div className="single-group-details">
                        <div className="d-flex flex-column">
                          {group.id !== "public" && (
                            <span>{dateFormatter(group.created)}</span>
                          )}

                          <span
                            className="cursor"
                            onClick={() => this.navigateToPage(group)}
                          >
                            <i className="material-icons ml-5">settings</i>
                          </span>
                          {group.id !== "public" && (
                            <>
                              {group.superAdmin == "1" && (
                                <span>{t("administrator")}</span>
                              )}
                              {group.admin == "1" &&
                                group.superAdmin != "1" && (
                                  <span>{t("moderator")}</span>
                                )}
                              {group.admin != "1" &&
                                group.superAdmin != "1" && (
                                  <span>{t("my_groups_member")}</span>
                                )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center">
                  {this.state.noDataTitle && t(`${this.state.noDataTitle}`)}
                </div>
              )}
            </div>

            {this.state.editing && (
              <EditGroup
                token={this.props.token}
                editing={this.state.editing}
                editedGroupId={this.state.groupId}
                history={this.props.history}
                stopEdit={() => {
                  this.setState({
                    editing: false,
                  });
                  this.getMyGroups();
                }}
              />
            )}
          </div>
        </div>
        <ShowInfoModal
          status={this.state.showInfoModal}
          onModalClose={() => this.setState({ showInfoModal: false })}
          text="cant_leave_group"
        />
      </>
    );
  }
}

export default withTranslation()(
  connect(mapStateToProps)(withRouter(MyGroups))
);
