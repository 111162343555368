import GroupFeed from "./feed/index";

export interface IPostsTabParams {
  token: string;
  groupId: any;
  groupData: any;
}

export const PostsTab = (props: IPostsTabParams) => {
  return <GroupFeed {...props} cutTop={true} />;
};
