import { param } from "jquery";
import { API_BASE_URL, API_BASE_HEADERS } from "../global";

import axios from "./AxiosProvider"

// const url = require("url");


export function getPosts(token, offset, groupId, search = "", postId = null, publicPosts = false) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: publicPosts ? 'GA' : 'G',
      offset: offset,
      incus: groupId,
      search: search,
      postId: postId,
      html: "y",
    },
  });
}  

export function removeCallToAction(postID,token){
     const postData = new FormData(); 

     postData.append("TMBP_ACC_TOKEN", token);
     postData.append("postID", postID);
     postData.append("action", "PCD");
  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function getFilterPosts(
  token,
  offset,
  groupId,
  search = "",
  postId = null
) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "68",
      offset: offset,
      incus: groupId,
      search: search,
      postId: postId,
      html: "y",
    },
  });
}

export function applyFilterOnPosts(params) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", params.token);
  postData.append("action", params.action);
  postData.append("GroupId", params.GroupId);
  postData.append("IsPersonalized", params.IsPersonalized);
  postData.append("action", params.action);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function savePost(token, data, edit = false) {
  console.log('data',data);
  const postData = new FormData();

  if (edit) {
    postData.append("action", "A");
    postData.append("NewMod", data.id);
  } else {
    postData.append("action", "S"); 

  }    

  if(data.LCP_cta.LPC_action !== ""){
  for (const key in data.LCP_cta) {
    if (Object.prototype.hasOwnProperty.call(data.LCP_cta, key)) {
      postData.append(`LCP_cta[${key}]`, data.LCP_cta[key]);
    }
  } 
}
 
   
  postData.append("TMBP_ACC_TOKEN", token);

  postData.append("LCP_organi", 0);
  postData.append("LCP_subject", data.subject);
  postData.append("LCP_mess", data.content);

  postData.append("LCP_comment", data.allowComments ? "y" : "n");
  postData.append("LCP_mayor", data.isPremium ? "y" : "n");
  postData.append("LCP_news", data.isNews ? "y" : "n");

  if (!data.isPremium) {
    postData.append("LCP_community", data.isSendToCommunity ? "y" : "n");
    postData.append("LCP_community_visible", data.isVisibleToUsers ? "y" : "n");
  }

  if (data.groupId && data.groupId!==0) {
    postData.append("LCP_organi", data.groupId);
    postData.append("LCP_intern", "y");
    postData.append(
      "LCP_post_visible_admin",
      data.isVisibleToAdmins ? "y" : "n"
    );
    postData.append("LCP_admin_post", data.canAdminPublish ? "y" : "n");

    postData.append("LCP_post_share_group", data.shareToGroups ? "y" : "n");

    postData.append("LCP_groups[]", JSON.stringify(data.groups));
  }

  const media = data.mediaFiles.filter((x) => x.file);
  console.log('media',media);

  for (var i = 0; i < media.length; i++)
    postData.append("AED_pic[]", media[i].file);

  const docs = data.docFiles.filter((x) => x.file);
  for (var i = 0; i < docs.length; i++)
    postData.append("LCP_doc[]", docs[i].file);

  const thumbs = data.thumbs.filter((x) => x.thumb);

  for (var i = 0; i < thumbs.length; i++)
    postData.append("AED_thumb[]", thumbs[i].thumb);

  var object = {};
  postData.forEach(function (value, key) {
    object[key] = value;
  });
  var json = JSON.stringify(object);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function deletePost(token, postID) {
  const postData = new FormData();
  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("Del", postID);
  postData.append("action", "E");
  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function deleteMediaFromPost(token, postID, mediaID) {
  console.log('called','deleteMediaFromPost');
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("Del", mediaID);
  postData.append("postID", postID);

  postData.append("action", "24");

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function deleteDocFromPost(token, postID, docID) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("Del", docID);
  postData.append("postID", postID);
  postData.append("action", "25");

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function likePost(token, postID, like = true) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "M",
      postL: postID,
      type: like ? "a" : "r",
    },
  });
}



export function favoritePost(token, postID, fav = true) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "F",
      postDL: postID,
      type: fav ? "a" : "r",
    },
  });
}

export function getPostData(token, postId) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "B",
      pID: postId,
    },
  });
}

export function getPostDataWithoutAuth(postId, userId) {
  return axios.get(API_BASE_URL, {
    params: {
      action: "B",
      pID: postId,
    },
  });
}

export function confirmPost(token, groupId, postId) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("groupID", groupId);
  postData.append("postID", postId);
  postData.append("action", "50");

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

//comments

export function getComments(token, postID) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,

      action: "R",
      ID: postID,
    },
  });
}

export function addComment(token, postID, message) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "C");
  postData.append("comID", postID);
  postData.append("comMes", message);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}
export function deleteComment(token, commentId) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", "D");
  postData.append("comDel", commentId);
  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}
export function reportPost(token, postID, message) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", 13);
  postData.append("mID", postID);
  postData.append("reportMsg", message);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function reportUser(token, userId, message) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", 72);
  postData.append("mID", userId);
  postData.append("reportMsg", message);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function getAnlyseData(token, postID) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,
      action: "I",
      pID: postID,
    },
  });
}

export function fetchNewsData(token) {
  return axios.get(API_BASE_URL, {
    params: {
      TMBP_ACC_TOKEN: token,

      action: 60,
    },
  });
}

export function closeNews(token, postId) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", token);
  postData.append("action", 61);
  postData.append("post_id", postId);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}

export function validatePromoCode(code) {
  const postData = new FormData();

  postData.append("TMBP_ACC_TOKEN", null);
  postData.append("action", 69);
  postData.append("promo", code);

  return axios.post(API_BASE_URL, postData, { headers: API_BASE_HEADERS });
}
