import { LinkArrowIcon as LinkArrowIconSvg } from "components/icons/LinkArrow";
import toRem from "lib/utils/toRem";
import styled, { css } from "styled-components";

export const InfoTabFiles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing["8"]};
`;
export const InfoTabFilesHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const InfoTabFilesHeaderItems = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing["5"]};
  align-items: center;
  @media ${({ theme }) => theme.mediaQueries.phone} {
    gap: ${({ theme }) => theme.spacing["1"]};
  }
`;
export const InfoTabFilesHeaderItem = styled.div<{ $active?: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 6px 22px;
  border: 1px solid transparent;
  border-radius: 32px;
  font-family: ${({ theme }) => theme.fontFamily.primary};
  color: #a0a0a0;
  font-weight: 600;
  transition: ${({ theme }) => theme.transition.default};
  &:hover {
    box-shadow: 1px 0px 49px 0px rgba(149, 184, 61, 0.2);
  }
  @media ${({ theme }) => theme.mediaQueries.phone} {
    padding: 4px 12px;
  }
  ${(props) =>
    props.$active &&
    css`
      border-color: #95b83d1c;
      color: ${({ theme }) => theme.colors.primary};
      box-shadow: 1px 0px 49px 0px #0000000f;
    `}
`;
export const InfoTabFilesSeeAll = styled.a`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing["4"]};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  font-size: 16px;
  font-weight: 500;
  line-height: ${toRem("20px")};
  color: ${({ theme }) => theme.colors.primary}!important;
  transition: ${({ theme }) => theme.transition.default};
  &:hover {
    opacity: 0.8;
  }
`;
export const LinkArrowIcon = styled(LinkArrowIconSvg)`
  width: 7px;
  height: 11px;
  color: ${({ theme }) => theme.colors.primary};
`;
export const InfoTabFilesContent = styled.div<{ $isExtended?: boolean }>`
  width: 100%;
  display: flex;
  overflow: hidden;
  ${({ $isExtended }) =>
    $isExtended &&
    css`
      width: calc(100% + 53px);
      margin-right: -53px;
    `}
`;
export const InfoTabFilesWrapper = styled.div`
  width: fit-content;
  display: flex;
  gap: 8px;
`;
export const InfoTabFilesItem = styled.div<{ $fixedWidthResponsive?: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  @media ${({ theme }) => theme.mediaQueries.phone} {
    ${({ $fixedWidthResponsive }) =>
      $fixedWidthResponsive
        ? css`
            width: 150px;
          `
        : css`
            width: calc(50% - 8px);
          `}
  }
`;
export const InfoTabFilesItemThumb = styled.div<{ $small?: boolean; $fullWidth?: boolean }>`
  width: 220px;
  height: 240px;
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  @media ${({ theme }) => theme.mediaQueries.phone} {
    width: 100%;
    height: 150px;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
      @media ${({ theme }) => theme.mediaQueries.phone} {
        width: 100%;
      }
    `}
  ${({ $small }) =>
    $small &&
    css`
      height: 150px;
    `}
  &:hover {
    img {
      opacity: 0.8;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: ${({ theme }) => theme.transition.default};
  }
`;
export const InfoTabFilesItemContent = styled.div`
  padding: ${({ theme }) => theme.spacing["3"]};
  font-family: ${({ theme }) => theme.fontFamily.primary};
  h4 {
    font-size: 16px;
    font-weight: 400;
    line-height: ${toRem("19px")};
    color: ${({ theme }) => theme.colors.palmLeaf};
    margin-bottom: ${({ theme }) => theme.spacing["3"]};
    @media ${({ theme }) => theme.mediaQueries.phone} {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  h5 {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray};
    margin-bottom: ${({ theme }) => theme.spacing["5"]};
  }
  > div {
    display: flex;
    gap: ${({ theme }) => theme.spacing["2"]};
  }
  span {
    font-size: 12px;
    font-weight: 500;
    line-height: ${toRem("14px")};
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const InfoTabModalGalleryContent = styled.div`
  width: 100%;
  display: flex;
`;
export const InfoTabModalGalleryWrapper = styled.div`
  column-count: 4;
  column-gap: 4px;
`;
export const InfoTabModalGalleryItem = styled.div`
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
  overflow: hidden;
  padding: 3px;

  img {
    max-width: 100%;
    display: block;
    grid-row: 1 / -1;
    grid-column: 1;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
  }
`;

export const InfoTabModalVideosWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${({ theme }) => theme.spacing["4"]};
  row-gap: ${({ theme }) => theme.spacing["8"]};
  @media ${({ theme }) => theme.mediaQueries.phone} {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const InfoTabDocumentsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing["4"]};
`;
