import React, { Component } from "react";
import IconButton from "../elements/buttons/IconButton";
import Upload from "./Upload";
import "./Upload.scss";
import { withTranslation } from "react-i18next";
import { Form, Modal, Toast, ToastBody } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Button from "../elements/buttons/Button";
import customvideothumb from "../../assets/video_player_icon.png";
import {
  notAllowedFileTypes,
  allowedMediaTypes,
} from "lib/utils/Commons";
import {  generateVideoThumbnails } from "lib/utils/thumbnails";

let thumbBase64Data = [];
let base64Data = [];
class FileList extends Component {
  state = {
    showPreviewModal: true,
    base64Data: [],
    caption: "",
    showErrorModal: false,
    errorMsg: "",
    showCameraIcon: false,
  };

  postThumbData = [];

  componentDidMount() {
    // if(this.props.type==="chat")
  }

  getAlert = () => {
    alert("getAlert from Child");
  };

  handleCloseModals = () => {
    thumbBase64Data = [];
    base64Data = [];
    this.props.closeModal();
  };

  onSendClick = () => {
    this.props.handleSend(this.props.id, thumbBase64Data, this.state.caption);

    thumbBase64Data = [];
    base64Data = [];

  };

  getMediaType = (type) => {
    let fileType = type.split("/")[0];
    switch (fileType) {
      case "image":
        return "p";
      case "video":
        return "v";
      default:
        return "d";
    }
  };

  generateThumnailForVideo = async (data, item) => {
    console.log(data, "thumb");
    try {
      const response = await fetch(data);
      const buffer = await response.arrayBuffer();
      const file = await new File([buffer], item.file.name, { type: "image/jpeg" });
      thumbBase64Data.push({ imageName: item.file.name, thumb: file });
      this.postThumbData.push({ imageName: item.file.name, thumb: file });

      // console.log('rjn ass', { file });
    } catch (error) {
      console.error('Error generating thumbnail:', error);
    }
  };


  handleUpload = async (e) => {
    let rawBase64 = [];
    let files = this.props.files;
    const { t } = this.props;
    var docType = "docs";
    var limit = 5;
    let maxFileSize = 65536;
    if (this.props.previews) {
      docType = "media";
      limit = 10;
    }
    for (let file of e.target.files) {

      let fileSize = Math.round(file.size / 1024);
      let fileType =
        file.type.split(".")[1] === undefined
          ? file.type.split("/")[1]
          : file.type.split(".")[1];
      const preview = URL.createObjectURL(file);
      let type = this.getMediaType(file.type)

      console.log(preview, "preview");

      file = {
        preview: preview,
        file: file,
        type: type,
      };
      if(this.props.type==='chat' && type==='v')
      this.props.onLoad(true)
      if (type === "v") {
        let response = await generateVideoThumbnails(file.file,2) 
        console.log(response,"response");
        this.generateThumnailForVideo(response[1], file)  
      }



      if (files.length < limit) {
        if (this.props.id === "mediaUpload")
          if (
            allowedMediaTypes.includes(fileType.toLowerCase()) &&
            fileSize < maxFileSize
          ) {

            files.push(file);
          } else {
            this.setState({
              errorMsg: "Please_upload_valid_document",
              showErrorModal: true,
            });
          }
        if (fileSize > maxFileSize) {
          this.setState({
            errorMsg: "Please_upload_less_then_64MB_document",
            showErrorModal: true,
          });
        }

        if (
          this.props.id === "documentUpload" ||
          this.props.id === "documentUpload2"
        ) {
          if (
            notAllowedFileTypes.includes(fileType.toLowerCase()) &&
            fileSize < maxFileSize
          ) {
            this.setState({
              errorMsg: "Please_upload_valid_document",
              showErrorModal: true,
            });
          } else if (fileSize > maxFileSize) {
            this.setState({
              errorMsg: "Please_upload_less_then_64MB_document",
              showErrorModal: true,
            });
          } else {
            files.push(file);
          }
        }
      } else {
        if (this.props.previews === undefined && files.length >= limit)
          alert(t("max_allowed_docs"));
        else if (files.length >= limit) alert(t("max_allowed_media"));
      }
    }

    if (files.length <= limit) {
      if (this.props.type === undefined) {
        // console.log({ thumbBase64Data });

        this.props.setFiles(files, this.postThumbData);
        // thumbBase64Data=[]
      } else {
        console.log({ files });
        this.props.setFiles(files);
      }
    }
    this.setState({
      showCameraIcon: false,
    }); 

     if(this.props.onLoad)
    this?.props?.onLoad(false)

  };

  handleRemove(item) {
    console.log(item,"sssL");

    if (!item.id && item?.file) {

      let files = this.props.files.filter((x) => x !== item);
      let filename = item?.file?.name;
      let thumbs = this.postThumbData.filter((x) => x.imageName !== filename);
      this.props.setFiles(files, thumbs);

      this.setState({
        showCameraIcon: true,
      });

    } else if (item.preview && item.file === undefined) {

      let files = this.props.files.map((me) => {
        if (me === item) {
          return {
            ...me,
            delete: true,
          };
        } else {

          return { ...me };
        }
      });

      this.setState({
        showCameraIcon: true,
      });
  
      this.props.setFiles(files, []);
    } else {
      item.delete = true;
      this.forceUpdate();
    }
  }   

  handleEnterKeyPress = (e) => {   
    console.log(e.key,"sed");
    if (e.key === 'Enter' && !e.shiftKey) {
      this.onSendClick()
    };
  }

  customRenderThumb = (children) =>
    children.map((item) => {
      return (
        <img
          src={item.props.src ? item.props.src : customvideothumb}
          width="50px"
          height="50px"
        />
      );
    });

  render() {
    const uploaded = this.props.files.filter((x) => !x.delete);
    const { t } = this.props;

    console.log("avatar", this.props.files)
    console.log("avatar uploaded", uploaded)

    return (
      <>
        <div className="form-group">
          {this.props.type === "chat" && this.props.files.length > 0 ? (
            <>
              <Modal
                centered
                show={this.state.showPreviewModal}
                size={"lg"}
                onHide={this.handleCloseModals}
                className=" custom-modal image-preview"
              >
                <IconButton
                  clear="true"
                  className="closeIcon"
                  icon="close"
                  handleClick={this.handleCloseModals}
                />
                <Modal.Body>
                  <div className="pb-5">
                    <b>{t("send_media_title")}</b>
                  </div>


                  <div className="slider">
                    {this.props.id === "mediaUpload" ? (
                      <Carousel
                        showArrows={false}
                        showStatus={false}
                        showIndicators={false}
                        renderThumbs={this.customRenderThumb}
                      >
                        {uploaded.map((item, i) =>
                          (item.file && item.file.type.includes("video")) ||
                            item.type === "v" ? (
                            <>
                              <video
                                id="uplodingvideo"
                                width="100%"
                                height="400px"
                                controls
                              >
                                <source src={item.preview} />
                              </video>
                             
                            </>
                          ) : (
                            <img src={item.preview} alt="" />
                          )
                        )}
                      </Carousel>
                    ) : (
                      <Carousel
                        showArrows={false}
                        showStatus={false}
                        showIndicators={false}
                      >
                        {uploaded.map((item, i) => (
                          <div>
                            <span>
                              <i className="material-icons mr-5 black">
                                insert_drive_file
                              </i>
                            </span>
                            <div> {item.file ? item.file.name : item.name}</div>
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </div>

                  <div className="caption">
                    <Form>
                      <Form.Group size="lg" controlId="topic">
                        <Form.Control
                          autoFocus
                          type="text"
                          placeholder={t("add_caption_title")}
                          value={this.state.caption}
                          onChange={(e) =>
                            this.setState({ caption: e.target.value })
                          }  
                          onKeyDown={this.handleEnterKeyPress}
                        />
                      </Form.Group>
                    </Form>
                  </div>

                  <div className="report-actions pt-3">
                    <Button
                      clear="true"
                      text={t("cancel_alert")}
                      handleClick={this.handleCloseModals}
                    />
                    <Button
                      clear="true"
                      text={t("send_button")}
                      handleClick={this.onSendClick}
                    />
                  </div>
                </Modal.Body>
              </Modal>
            </>
          ) : (
            <div
              className={
                "upload-list " +
                (this.props.className ? this.props.className : "")
              }
            >
              {this.props.previews && this.props.type === undefined ? (
                <div className="upload-list-media">
                  {console.log("avatar uploaded below", uploaded)}
                  {uploaded.length > 0 && uploaded?.map((item, i) =>
                    (item.file && item.file.type.includes("video")) ||
                      item.type === "v" ? (
                      <div key={i} className="single-media">
                        <video>
                          <source src={item.preview} />
                        </video>
                        <IconButton
                          icon="close"
                          clear={true}
                          className="icon-mr-0"
                          handleClick={() => this.handleRemove(item)}
                        />
                      </div>
                    ) : (
                      <div key={i} className="single-media">
                        <img src={item.preview} alt="" />
                        <IconButton
                          icon="close"
                          clear={true}
                          className="icon-mr-0"
                          handleClick={() => this.handleRemove(item)}
                        />
                      </div>
                    )
                  )}
                </div>
              ) : (
                <div className="upload-list-documents">
                  {uploaded.map((item, i) => (
                    <span key={i}>
                      <span>
                        <i className="material-icons mr-5 black">attach_file</i>
                        {item.file ? item.file.name : item.name}
                      </span>
                      <IconButton
                        icon="close"
                        clear={true}
                        className="icon-mr-0"
                        handleClick={() => this.handleRemove(item)}
                      />
                    </span>
                  ))}
                </div>
              )}

              {(!this.props.single ||
                (this.props.single && this.props.files.length === 0) ||
                this.state?.showCameraIcon) && (
                  <Upload
                    id={this.props.id}
                    accept={this.props.fileTypes}
                    icon={this.props.icon}
                    text={this.props.text}
                    single={this.props.single}
                    handleUpload={this.handleUpload}
                  />
                )}
            </div>
          )}
        </div>
        {
          <Modal
            show={this.state.showErrorModal}
            onHide={() => this.setState({ showErrorModal: false })}
            className="custom-modal error-modal"
          >
            <IconButton
              clear="true"
              className="closeIcon"
              icon="close"
              handleClick={() => this.setState({ showErrorModal: false })}
            />
            <div>{t(this.state.errorMsg)}</div>
          </Modal>
        }
      </>
    );
  }
}
export default withTranslation()(FileList);
