import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import './common.scss'

// import { Linking, Platform, Text, StyleSheet, Alert } from "react-native";

// let MAIN= `Lorem Ipsum is sfj @test fdfdfil fdfdilfd fddfln simply dummy https://www.lipsum.com/ text of the printing and #test typesetting industry. Lorem https://www.lipsum.com/ Ipsum has demdsukkjo@gmail.com been the industry's standard dummy text ever since the 1500s, when an unknown printer took https://www.lipsum.com/ a galley of type and scrambled it to make a type specimen #test book. It has survived not only five centuries, but also the leap #aaa #bbb #ccc into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,  and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`

// const PATTERN_HASHTAG = /(^|\s)(#[a-z0-9äöüäöÄÖÜß ÄÖüäöÄÖÜß ß\d-_]+)/gi;
const PATTERN_HASHTAG= /(^|\s)(#[a-z0-9äöüäöÄÖÜßÄÖüäöÄÖÜßß\d-_]+)/gi

const PATTERN_MENTION = /(^|\s)(@[a-z\d-_]+)/gi;
const PATTERN_EMAIL = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
const PATTERN_URL =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9üäöÄÖÜß ][a-zA-Z0-9-üäöÄÖÜß ]+[a-zA-Z0-9üäöÄÖÜß ]\.[^\s]{2,}|www\.[a-zA-Z0-9üäöÄÖÜß ][a-zA-Z0-9-üäöÄÖÜß ]+[a-zA-Z0-9üäöÄÖÜß ]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9üäöÄÖÜß ]+\.[^\s]{2,}|www\.[a-zA-Z0-9üäöÄÖÜß ]+\.[^\s]{2,})/gi;

const matchesWith = (str, pattern) => {
  let match = null;
  const arr = [];
  while ((match = pattern.exec(str)) != null) {
    arr.push([match, pattern]);
  }
  return arr;
};

const splitStringByMatches = (str, matches) => {
  const arr = [];
  let o = 0;

  matches.forEach(([match, pattern]) => {
    const { index } = { ...match };
    const text = match[match.length - 1];
    arr.push([str.slice(o, index), null]);
    arr.push([str.slice(index, index + text.length + 1), pattern]);
    o = index + text.length + 1;
  });
    arr.push([str.slice(o, str.length), null]);
  return arr.filter(([s]) => s.length > 0);
};

const TwitterTextView = ({
  children = "",
  extractHashtags,
  onPressHashtag,
  hashtagStyle,
  extractMentions,
  onPressMention,
  mentionStyle,
  extractLinks,
  onPressLink,
  linkStyle,
  extractEmails,
  onPressEmail,
  emailStyle,
  ...extraProps
}) => {


  const [isReadMore, toggleReadMore] = useState(false);
  const { t } = useTranslation();

  const str =(typeof children === "string" && children) || "";
   
  // console.log(str,"aaaa ssss");

  useEffect(() => {
    if(str?.length>200)
    toggleReadMore(true)
  
  }, [str?.length]) 

  // console.log(extractHashtags,extractMentions,extractEmails,extractLinks,"extract final");

  const patterns = [
    !!extractHashtags && PATTERN_HASHTAG,
    !!extractMentions && PATTERN_MENTION,
    !!extractEmails && PATTERN_EMAIL,
    !!extractLinks && PATTERN_URL,
  ].filter((e) => !!e); 

  // console.log(patterns,"patterns");

  const matches = []
    .concat(...patterns.map((pattern) => matchesWith(str, pattern)))
    .filter((e) => !!e)
    .sort(([a], [b]) => ({ ...a }.index - { ...b }.index)); 


    // console.log(matches,"matches");

  const onPress = {
    [PATTERN_HASHTAG]: onPressHashtag,
    [PATTERN_MENTION]: onPressMention,
    [PATTERN_EMAIL]: onPressEmail,
    [PATTERN_URL]: onPressLink,
  };
  const style = {
    [PATTERN_HASHTAG]: hashtagStyle,
    [PATTERN_MENTION]: mentionStyle,
    [PATTERN_EMAIL]: emailStyle,
    [PATTERN_URL]: linkStyle,
  };

  const mainString = str;
  const arr = useRef([]);
  const hiddenIndex = useRef(null)
  return (
    <div {...extraProps}>
      {splitStringByMatches(str, matches).map(([str, pattern], i) => { 
       
        if(i===0){
          hiddenIndex.current = null
          arr.current = []
          arr.current.push(str.length)
        } else{
          arr.current.push(arr.current[i-1]+str.length)
        }
        let child = str;
        if(isReadMore && arr.current[i]>200 && hiddenIndex.current === null) {
          hiddenIndex.current = i;
    
          const lastLength = i==0? 0: arr.current[i-1]
          // console.log(str,str.length,"final str test",lastLength, arr.current[i]);
          child = str.slice(0,200-lastLength)+' ...'
        }
        if(hiddenIndex.current !== null && i>hiddenIndex.current && isReadMore) {
          // console.log('setting final null', hiddenIndex.current, i)
         child = null 
        }
        // console.log(child, 'final' , i, str)

        const isUrl = {[PATTERN_URL]:true}[pattern];
        
        return (
          <span
            className={`twitterspan ${isUrl ? 'twitterspan--link': ''}`}
            key={i}
            style={style[pattern]}
            onClick={(e) => {           
              const handle = onPress[pattern];
              if (handle) {
                return handle(e, str);
              }
              return undefined;
            }}
            children={child} 
          ></span>
        );
      })
      }
      <br/>
      <div className="main-Div-moreLess">
      {str?.length>200&&<span style={hashtagStyle} className="readMoreLess" onClick={()=>toggleReadMore(!isReadMore)}>{isReadMore? "..." + t("read-more-title"): "..."+ t("read-less-title")}</span>}

      </div>
    </div>
  );
};

const textStyleProps = PropTypes.oneOfType([
  PropTypes.shape({}),
  PropTypes.number,
]);

TwitterTextView.propTypes = {
  children: PropTypes.string,
  extractHashtags: PropTypes.bool,
  onPressHashtag: PropTypes.func,
  hashtagStyle: textStyleProps,
  extractMentions: PropTypes.bool,
  onPressMention: PropTypes.func,
  mentionStyle: textStyleProps,
  extractLinks: PropTypes.bool,
  onPressLink: PropTypes.func,
  linkStyle: textStyleProps,
};

TwitterTextView.defaultProps = {
  children: "",
  extractHashtags: true,
  onPressHashtag: (e, hashtag) => {
    const msg = `Hashtag: "${hashtag}"`;
  },
  // hashtagStyle: styles.linkStyle,
  extractMentions: true,
  onPressMention: (e, mention) => {
    const msg = `Mention: "${mention}"`;
  },
  extractLinks: true,
  // onPressLink: (e, url) =>console.log(e,url)
  // Linking.canOpenURL(url).then(canOpen => !!canOpen && Linking.openURL(url)),
  // linkStyle: styles.linkStyle,
  // extractEmails: true,
  // onPressEmail: (e, url) =>
  //   Linking.canOpenURL(`mailto:${url}`).then(
  //     canOpen => !!canOpen && Linking.openURL(`mailto:${url}`)
  //   ),
  //   emailStyle: styles.linkStyle
};

export default TwitterTextView;
