import { API_BASE_URL, API_BASE_HEADERS } from "../global";

// import {  } from "react-i18next";


import axios from "axios";
import { useAuthDispatch } from "../lib/context/authContext.js";
import i18next from "i18next";
// import { useTranslation } from "react-i18next";
const AxiosInstance = axios.create({
  timeout: 235000,
});


let isLoggedOut = false

function setTimeout() {
  setTimeout(window.location.replace('/#/login'), 1000);
    return window.location.reload()

}


axios.default.interceptors.request.use(
  function (config) {
    const data = config.data;
    const lang = localStorage.getItem("i18nextLng");

    if (data && data.append) {
      data.delete("lang");
      data.delete("Lang");
      data.append("Lang", lang.split("-")[0]);
    } else {
      const params = config.params;
      params.Lang = lang;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(function (response) {
// const { t } = useTranslation();

  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const data = response?.data;
  const status = response?.status
  // console.log('response',data,status,(status===200 && data?.ER && data.ER==="relogin"))

  if (status === 200 && data?.ER && data.ER === "relogin" && !isLoggedOut) {
    isLoggedOut = true
    localStorage.removeItem('user');
    localStorage.removeItem('profile');
    localStorage.removeItem('i18nextLng');
    localStorage.removeItem('ga');


    alert(i18next.t("change_pass_confirmation"))

    //  window.location.replace("/login");
    // alert("your session is expired")
    // return window.location.reload()
    window.location.replace('/#/login')
    return window.location.reload()
  }
  else if (status === 200 && data?.ER && data.ER === "relogin" && isLoggedOut) {

    return false
  }

  else {
    return response
  }
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

// const logout = async (e) => {
//   try {
//     await logoutUser(authDispatch)
//       .then((res) => {
//         history.replace("/login");
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   } catch (error) {
//     console.log(error);
//   }
// };

export default AxiosInstance;

