import styled, { css } from "styled-components";

export const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  border-bottom: 1px solid #c9d7e5;
`;

export const Tab = styled.a<{ $active?: boolean }>`
  width: 194px;
  height: 27px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.3px;
  margin-bottom: -1px;
  transition: all 0.3s ease;
  border-bottom: 1px solid transparent;

  ${(props) =>
    props.$active
      ? css`
          color: #000 !important;
          border-bottom: 2px solid #95b83c;
        `
      : css`
          color: #687684 !important;
        `}

  &:hover {
    color: #000 !important;
    border-color: #95b83c;
  }
`;

export const CardHeading = styled.h5`
  color: #bcbcbc;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 14px;
`;

export const CardDevider = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: #e0ebc6;
  margin: 30px 0;
  @media ${({ theme }) => theme.mediaQueries.phone} {
    margin: 15px 0;
  }
`;

export const CardText = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #000000;
`;

export const InfoContactList = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
`;

export const InfoContactListItem = styled.a`
  line-height: 31px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  border-radius: 10px;
  text-decoration: none;
  padding-right: 15px;
  transition: all 0.5s ease;

  span {
    background-color: #cadc9e;
    border-radius: 10px;
    width: 31px;
    height: 31px;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    i {
      font-size: 20px;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
    color: #000;
  }

  &.disabled {
    pointer-events: none;
    color: #a3ab90;
    opacity: 0.7;
  }
`;

export const PersonList = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: 20px;
  @media ${({ theme }) => theme.mediaQueries.phone} {
    flex-wrap: wrap;
    gap: 15px;
  }
`;
export const PersonListItem = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }

  span {
    font-size: 20px;
    color: #000;
    line-height: 20px;
  }
`;

export const FormGroupList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  border-radius: 16px;

  label {
    width: calc(100% - 20px);
    margin-left: 20px;
    padding: 20px 20px 20px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    border-bottom: 1px solid #e3e3e3;

    &:last-child {
      border-bottom: none;
    }

    .MuiFormControlLabel-label,
    .MuiFormControlLabel-label.Mui-disabled {
      color: #000;
    }
  }
`;
