import Radio from "../../elements/radio";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useParams } from "react-router";
import { shareGroupLink } from "../../../api/user";
import Button from "../../elements/buttons/Button";
import { Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import SharePostDialog from "../../posts/sharePostDialog/sharePostDialog";
import { dotDateFormatter } from "lib/utils/Commons";
import moment from "moment";
import QRCodeGenerator from "qrcode.react";
import IconButton from "../../elements/buttons/IconButton";


const ShareLink = (props) => {
  const { t } = useTranslation();

  const [isOTU, setOTU] = useState(false);
  const [isValidAlways, setValidAlways] = useState(true);
  const params = useParams();
  const [sharePost, setSharePost] = useState(false);
  const [groupLink, setGroupLink] = useState("");
  const [expiryDate, setExpiryDate] = useState(); 
  const [expiryDateValidation,setExpiryDateValidation] = useState(false)
 
  const [showQRModal, toggleQRModal] = useState(false);

  const shareLink = (type) => {       
           console.log(isValidAlways);    
      if(!isValidAlways && expiryDate == undefined){
        setExpiryDateValidation(true)
        return
      }else{
        setExpiryDateValidation(false)
      }
      
    const data = {
      groupId: params.id,
      linkType: isOTU ? "onetime" : "multipletime",
      linkFor: "share link",
      linkAlwaysValid: isValidAlways ? "y" : "n",
      linkExpireDate: expiryDate !== undefined ? dotDateFormatter(expiryDate) : "",
    };
    shareGroupLink(props.token, data).then((res) => {
      if (res.data.OK) {
        setGroupLink(res.data.link);
        setSharePost(type !== "generate" ? true : false);
        toggleQRModal(type !== "generate" ? false : true);
      }
    });
  };

  const downloadQRCode = () => {
    const qrCodeURL = document
      .getElementById("qrCodeEl")
      .toDataURL("image/jpeg");

    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = "QR_Code.png";
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <>
      <div className="share-link-container">
        {/* <div className="font-weight-bold mb-3">{t("share_link")}</div> */}
        <h4 className="pt-2 pb-2">{t("share_link")}</h4>

        <div className="radiobtncont row">
          <div className="col-sm-12 mb-4">
            <div>{t("link_type")}</div>
            <div className="row">
              <div className="col-md-6">
                <Radio
                  value="multipletime"
                  selected={isOTU ? "onetime" : "multipletime"}
                  text={t("multiple_use")}
                  onChange={() => setOTU(false)}
                  className="customradio"
                />
              </div>
              <div className="col-md-6">
                <Radio
                  value="onetime"
                  selected={isOTU ? "onetime" : "multipletime"}
                  text={t("one_time_use")}
                  onChange={() => setOTU(true)}
                  className="customradio"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12">
            <div>{t("expiration_date")}</div>
            <div className="row">
              <div className="col-md-6">
                <Radio
                  value="Always valid"
                  selected={isValidAlways ? "Always valid" : "Valid until"}
                  text={t("always_valid")}
                  onChange={() => setValidAlways(true)}
                  className="customradio"
                />
              </div>
              <div className="col-md-6">
                <Radio
                  value="Valid until"
                  selected={isValidAlways ? "Always valid" : "Valid until"}
                  text={t("valid_until")}
                  onChange={() => setValidAlways(false)}
                  className="customradio"
                />
              </div>
            </div>

            {!isValidAlways && (
              <div>
                <div>{t("expiry_date")}</div>

                <DatePicker
                  selected={expiryDate}
                  onChange={(date) => setExpiryDate(date)}
                  minDate={moment().toDate()} 
                  
                />
              </div>
            )}
          </div>
        </div>

        {/* <input type="date" onClick={(e) => handleDate(e)} /> */} 
       {expiryDateValidation &&( <div style={{display:"flex",justifyContent:"center",paddingTop:"8px"}} >
          <span style={{color:"red"}} >{t("expiry_date_message")}</span>
        </div>)}

        <div className="text-center pt-4">
          <Button text={t("share_link")} handleClick={() => shareLink()} />
          <Button
            text={t("generate_qr_code")}
            handleClick={() => shareLink("generate")}
            className="ml-10"
          />
        </div>

        <div className="text-center pt-4"></div>

        {sharePost && (
          <SharePostDialog
            showModal={sharePost}
            shareUrl={groupLink}
            shareUrlType={"group"}
            handleClose={() => setSharePost(false)}
          />
        )}
      </div>

      <Modal
        show={showQRModal}
        onHide={() => toggleQRModal(false)}
        className="custom-modal"
      >
        <IconButton
          clear="true"
          className="closeIcon"
          icon="close"
          handleClick={() => toggleQRModal(false)}
        />

        <div className="text-center">
          {groupLink !== "" && (
            <QRCodeGenerator
              value={groupLink}
              id="qrCodeEl"
              includeMargin={true}
              size={250}
            />
          )}
          <div className="pt-3">
          
          <Button
            text={t("download_qr_code")}
            handleClick={() => downloadQRCode()}
          />
        </div>

          
        </div>
        
      </Modal>
    </>
  );
};

export default ShareLink;
