import React, { useContext, useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import HistoryListener from "components/shared/HistoryListener";
import "./Home.scss";

import { useLocation, useNavigate, Outlet } from "react-router-dom";
import { checkGroupCode } from "../../api/groups";
import { useSocketContext } from "lib/context/socketContext";
import { useTranslation } from "react-i18next";
import { getGroupAdmins, getShowRegional } from "../../api/common";
import { getMyDefaultGroup } from "../../api/groups";
import GlobalStateContext from "lib/context/global-state/GlobalStateContext";
import { useAppDispatch } from "lib/store";
import * as GlobalStore from 'lib/store/global';

function Home(props) {
  // const { connect } = useSocketContext();
  const [isMenu, setMenu] = useState(false);
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const { globalState } = useContext(GlobalStateContext);

  // useEffect(() => {
  //   connect(props.token);
  // }, []);

  useEffect(() => {
    if (props.token) {
      getGroupAdmins(props.token, "").then((res) => {
        if (res.data.row) {
          const data = res.data.row;
          let modifiedData;
          let userDetails = JSON.parse(localStorage.getItem("profile"));
          if (userDetails) {
            console.log(userDetails.userId);
            modifiedData = data.filter(
              (me) => me.FK_userID !== userDetails.userId
            );
          }
          localStorage.removeItem("ga");
          localStorage.setItem("ga", JSON.stringify(modifiedData));
        }
      });
      getRegionalShow();

      getMyDefaultGroup();

      // getShowRegionals(props.token)
    }
  }, [props.token]);

  const getRegionalShow = async () => {
    try {
      const response = await getShowRegional(props.token);
      if (response.data.OK) {
        let data = response?.data?.show_regional === "y";
        dispatch(GlobalStore.getShowRegionals(data));
      }
    } catch (e) {
      console.log(e);
    }
  };

  getMyDefaultGroup(props.token).then((res) => {
    if (res.data.OK) {
      console.log(res.data.data);
      dispatch(GlobalStore.getMyDefaultGroupId("test"));
      //   this.setState({ defaultGrpId: res.data.data });
    }
  });

  useEffect(() => {
    if (location) if (location.pathname.includes("Gj9J")) handleShareGroup();
  }, [location.pathname]);

  function handleShareGroup() {
    const groupCode = location.pathname.split(",,")[1].split(",")[0];
    // console.log("code", groupCode,);

    checkGroupCode(props.token, groupCode).then((res) => {
      if (res.data.OK) {
        const groupId = res.data.OK;
        const encodeInviteCode = encodeURIComponent(groupCode);
        // console.log('en',encodeInviteCode);
        return history(`/group/${groupId}/about?gid=${encodeInviteCode}`);
      } else {
        if (res.data.ER === "Die Einladung wurde bereits verwendet.") {
          alert(t("invite_code_already_used"));
        }
        history("/");
      }
    });
  }
  return (
    <HistoryListener>
      {
        !globalState.embedded &&
        <Navbar
          token={props.token}
          isMenu={isMenu}
          setMenu={setMenu}
          profile={props.profile}
        />
      }
      <div className="main-container container-fluid">
        <Outlet />
      </div>
    </HistoryListener>
  );
}

export default Home;
