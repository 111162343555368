import { useDispatch, useSelector } from "react-redux";
import t from "i18next";
import "./Groups.scss";

// import { getMyGroups, MY_GROUPS } from "../../redux/actions";
import { useEffect } from "react";
// import { getMyGroups } from "../../api/groups";
import { useState } from "react";
import GroupCard from "../commons/GroupCard";
import { Modal } from "react-bootstrap";
import IconButton from "../elements/buttons/IconButton";
import { getMyGroups } from "../../api/groups";
import { useTranslation } from "react-i18next";
import locciIcon from "../../assets/newsIcon.png";
import ConfirmPostDialog from "../posts/confirmPostDialog/confirmPostDialog";
import * as GlobalStore from 'lib/store/global';
import { useAppSelector } from "lib/store";


const MyGroupModal = (props) => {
  const { t, i18n } = useTranslation();

  const [showconfirmationDialog, setShowconfirmationDialog] = useState(false);
  const [groupData, setGroupData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(props.data);
  const showRegionalData = useAppSelector(GlobalStore.selectShowRegionalData)

  useEffect(() => {
    if (searchText?.length > 1) searchData();
    else if (searchText === "") {
      let data = showRegionalData ? props.data :
        props?.data?.filter((data) => {
          return data.id !== 0
        })
      setFilteredData([...data]);
    }
  }, [searchText]);

  const searchData = () => {
    let result = props.data.filter((me) => {
      let name = me.name.toLowerCase();
      return name.includes(searchText.toLowerCase().trim());
    });

    let data = showRegionalData ?result:
    result?.filter((data)=> { 
     return  data.id!==0
  }  ) 
    setFilteredData([...data]);
  };

  useEffect(() => {


    if (props.data) {
      let data = showRegionalData ? props.data :
        props?.data?.filter((data) => {
          return data.id !== 0
        })
      setFilteredData([...data]);
    }
  }, [props?.data]);

  const onCardClick = (group) => {
    setGroupData(group);
    setShowconfirmationDialog(true);
  };

  return (
    <>
      <Modal
        centered
        show={props.editing}
        onHide={() => props.closeModal()}
        backdrop="static"
        contentClassName="group-list-popup"
      >
        <Modal.Body>
          <IconButton
            clear="true"
            className="icon-mr-4 close-modal"
            icon="close"
            handleClick={() => props.closeModal()}
          />

          <div>
            <div className="ceatepostheading">
              {props?.type === "sharedGroups"
                ? t("select-group-title")
                : t("select-group-create-post-title")}
            </div>

            <div className="search">
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                aria-label="Search"
                value={searchText}
                autoFocus
                onChange={(e) => setSearchText(e.target.value)}

              // onKeyUp={() => this.searchData()}
              />
              {/* { searchLoading&&  <div className="loading">
              <Spinner animation="border"  size="sm"/>
              </div>} */}
            </div>
            {console.log({ filteredData })}
            <div className="scroll">
              {props?.type !== "sharedGroups"
                ? filteredData &&
                filteredData?.map(
                  (me) =>
                    me.UCM_IsPersonalized === "y" &&
                    (me.allow_post === "1" ||
                      me.admin === "1" ||
                      me.superAdmin === "1") && (
                      <GroupCard
                        group={me}
                        onCardClick={() => props.onCardClick(me)}
                      />
                    )
                )
                : filteredData &&
                filteredData.map(
                  (me) =>
                    (me.allow_post === "1" ||
                      me.admin === "1" ||
                      me.superAdmin === "1" ||
                      me.community === "y") && (
                      <GroupCard
                        group={me}
                        onCardClick={() => onCardClick(me)}
                      />
                    )
                )}
            </div>

            {showconfirmationDialog && (
              <ConfirmPostDialog
                create={false}
                deleting={true}
                handleClose={() => setShowconfirmationDialog(false)}
                groupId={groupData.id}
                groupName={groupData.name}
                onSubmit={() => {
                  props.onCardClick(groupData);
                }}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyGroupModal;
