import List from "@mui/material/List";
import EmojiPicker, { EmojiClickData, SkinTones } from "emoji-picker-react";
import React, { Fragment, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { addComment, deleteComment, getComments } from "../../../api/posts";
import Button from "../../elements/buttons/Button";
import IconButton from "../../elements/buttons/IconButton";

import { Divider } from "@mui/material";
import PostCommentSingle from "./PostCommentsSingle";

const PostComments = (props: {
  token: string;
  post: any;
  showComments: boolean;
  showUserDetails: (userID: string) => void;
  commentsCountChanged: (direction: "UP" | "DOWN") => void;
}) => {
  const { t } = useTranslation();
  const { post, showComments } = props;
  const [comments, setComments] = useState([] as any[]);
  const [newComment, setNewComment] = useState("");
  const [isStickerVisible, setIsStickerVisible] = useState(false);

  const fetchComments = () => {
    getComments(props.token, post.loci_postID).then((res) => {
      if (res.data.OK) {
        const data = res.data.data;
        setNewComment("");
        setComments(data);
      }
    });
  };

  const addCommentForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addComment(props.token, post.loci_postID, newComment?.trim()).then((res) => {
      if (res.data.OK) {
        fetchComments();
        props.commentsCountChanged("UP");
      }
    });
  };

  const onDeleteComment = (index: number) => {
    deleteComment(props.token, comments[index].loci_commentID).then((res) => {
      if (res.data.OK) {
        fetchComments();
        props.commentsCountChanged("DOWN");
      } else {
        alert(JSON.stringify(res.data));
      }
    });
  };

  const onEmojiClick = () => setIsStickerVisible((value) => !value);
  const onEmojiSelect = (emoji: EmojiClickData) => {
    setNewComment((prevState) => prevState.concat(emoji.emoji));
  };

  useEffect(() => {
    if (props.showComments) fetchComments();
  }, [props.showComments]);

  return (
    <>
      {showComments && (
        <div className='single-post-comments'>
          {comments && (
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {comments.map((item, i) => (
                <Fragment key={item.loci_commentID}>
                  <PostCommentSingle
                    comment={item}
                    index={i}
                    deleteComment={onDeleteComment}
                    onUserImgClick={props.showUserDetails}
                  />
                  {i !== comments.length - 1 && (
                    <Divider variant='inset' component='li' sx={{ marginLeft: "56px" }} />
                  )}
                </Fragment>
              ))}
            </List>
          )}

          {post.LCP_comment === "y" && (
            <div className={"new-comment " + (comments?.length ? "" : "mt-3")}>
              <Form className='new-comment-form' onSubmit={addCommentForm}>
                <div className='d-flex'></div>
                <Form.Group controlId='newComment' className='form-group d-flex'>
                  <Form.Control
                    autoFocus
                    type='text'
                    as='textarea'
                    required
                    placeholder={t("placeholder_input_comment")}
                    value={newComment}
                    autoComplete='off'
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart(); // Trim the input value
                      setNewComment(trimmedValue); // Set the trimmed value
                    }}
                    data-emoji-input='unicode'
                  />
                  <Modal centered show={isStickerVisible} onHide={onEmojiClick}>
                    <EmojiPicker
                      onEmojiClick={onEmojiSelect}
                      skinTonesDisabled={true}
                      defaultSkinTone={SkinTones.MEDIUM_DARK}
                      height={"400px"}
                      width={"100%"}
                    />
                  </Modal>
                  <IconButton
                    icon='emoji_emotions'
                    clear={true}
                    className={"emoji-size"}
                    handleClick={onEmojiClick}
                    text={""}
                  />
                </Form.Group>

                <Button text={t("comment_button")} type='submit' />
              </Form>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PostComments;
