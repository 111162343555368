import React, { Component, useTransition } from "react";
import { Link } from "react-router-dom";
import { getMyProfile, getProfile } from "../../api/user";
import { useAuthState } from "lib/context/authContext";
import IconButton from "../elements/buttons/IconButton";
import "./Navbar.scss";
import NavbarActions from "./NavbarActions";
import { getMyGroups, leaveGroup } from "../../api/groups";
import { mapStateToProps } from "lib/store/mapStateToProps";
import * as GlobalStore from 'lib/store/global';
import LocciLogos from "../../../src/assets/locciLogo.png";

import {
  Dropdown,
  Form,
  Spinner,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
//import { useTranslation } from 'react-i18next';
import { useTranslation, withTranslation } from "react-i18next";
import i18n from "i18next";
import { determineLanguageAPI } from "../../i18n";
import { connect } from "react-redux";
import { withRouter } from "../customNavigate/withRouter";
// import { matchPath } from "react-router-dom";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      profile: {},
      groups: [],
      isSearch: false,
    };
  }

  componentDidMount() {
    if (this.props.token){
      this.fetchMyGroupd();  
      this.getMyProfile();
      // if(this.props.profile){
      //   this.setState({profile:this.props.profile})
      // }else{
      //   this.getMyProfile();
      // }
    }
     
   
  }

  componentDidUpdate(prevProps) {
    if (prevProps.token !== this.props.token) {
      console.log("You rocks");
      this.getMyProfile();
      this.getMyProfile();
    }      
    if (prevProps.profile !== this.props.profile) {
      this.setState({ profile: this.props.profile });
    } 
  }
  getMyProfile() {
    getProfile(this.props.token).then((res) =>
      this.setState({ profile: res.data.OK })
    );
  }

  fetchMyGroupd() {
    getMyGroups(this.props.token, this.props.query).then((res) => {
      if (res.data.OK) {
        this.setState({ groups: res.data.grp });

        // if (this.state.groups === undefined || this.state.groups === [])
        //   this.setState({ noDataTitle: "empty_grp_list" });
      }
    });
  } 

  const 

  render() {
    document.title = i18n.t("app_title");
    const { t } = this.props;
     console.log(this.props.profile,"xxx , sec");
    return (
      <div className="site-navbar">
      {console.log("feed data 1",this.props?.showAddPost)}
        <div className="container-fluid navbar-content">
          <span className="navbar-left-side navbar-logo">
            {/* {this.props.token && (
              <IconButton
                icon="menu"
                className={this.props.isMenu ? " rotate90" : ""}
                clear={true}
                handleClick={() => this.props.setMenu(!this.props.isMenu)}
              />
            )} */}
            <Link to={this.props.token ? "/" : "/login"}>
              <span id="NavTitle" className="navbar-title">
                {/* {i18n.t("locci_navbar")} */}

                <img src={LocciLogos} />
              </span>
            </Link>
          </span>
          {this.props.token && this.state.profile ? (
            <NavbarActions
              data={this.state.profile}
              myGroups={this.state.groups}
              token={this.props.token}
              onPublicFeedToggle={() => this.getMyProfile()}
            />
          ) : (
            <></>
          )}
        </div>
        <div
          className={`${!localStorage.getItem('profile')&& "whiteNav"}
         ${"site-navbar-bottom"}`}
        >
          <div className="bottom-row"> 
          {console.log(this.props.matchPath,this.props?.showAddPost,"xxx")}
            {(this.props.location.pathname === "/" ||
              (this.props.matchPath && this.props?.showAddPost ) ||
              this.props.location.pathname === "/public-feed" ||
              this.props.location.pathname === "/inbox") && (
              <span
              //  style={{display:"none"}}
               className="addPost">
                <i className="material-icons white">control_point</i>
              </span>
            )}
            {this.props.token && this.state.profile ? (
              <NavbarActions
                data={this.state.profile}
                myGroups={this.state.groups}
                token={this.props.token}
                onPublicFeedToggle={() => this.getMyProfile()}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  search: GlobalStore.search
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar)));

//export default Navbar;
// export default withTranslation()(Navbar);
