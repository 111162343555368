import React, { Component } from "react";
import "./Button.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
function IconButton(props) {
  const { t, i18n } = useTranslation();

  return (
    <button
      name={props.name}
      className={classNames("btn", "btn-icon", props.className, {
        "icon-btn-clear": props.clear,
      })}
      style={{ ...(props.styles || {}), justifyContent: props.icon === 'groups' && 'center' }}
      type={props.type ? props.type : "button"}
      onClick={props?.handleClick}
      disabled={props.disabled}
    >
      {props.icon === 'groups' ?
        <i
          className={classNames("material-icons ", {
            "material-icons-outlined": props.outlined,
          })}

        >
          {props.icon}
        </i> :
        <>
          {!props.iconRight && (
            <span className="iconleftspan">
              <i
                className={classNames("material-icons ", {
                  "material-icons-outlined": props.outlined,
                })}

              >
                {props.icon}
              </i>
            </span>
          )}
          {props.text ? (
            props.translateText ? (
              <span className={props.iconRight ? "iconrightspan" : "iconleftspan"}>
                {t(props.text)}
              </span>
            ) : (
              <span className={props.iconRight ? "iconrightspan" : "iconleftspan"}>
                {props.text}
              </span>
            )
          ) : null}

          {props.iconRight && (
            <span className="iconrightspan">
              <i
                className={classNames("material-icons ", {
                  "material-icons-outlined": props.outlined,
                })}
                onClick={props?.iconClick}
              >
                {props.icon}
              </i>
            </span>
          )}
          {props.image && (
            <span className="pl-2">
              <img src={props.image} width={30} height={30} />
            </span>
          )}
        </>
      }

    </button>
  );
}
export default IconButton;
