import { useEffect, useState } from "react"
import EditPost from "."
import { getMyGroups } from "../../../api/groups"
import MyGroupModal from "../../groups/MyGroupModal"
import locciIcon from "../../../assets/newsIcon.png";
import { getProfile } from "../../../api/user";


const EditPostContainer = (props) => {    
    const [displayGroup, setDisplayGroup] = useState(true)
    const [showEditPost, setshowEditPost] = useState(props?.groupData ? true : false)
    const [groupData, setGroupData] = useState((props?.groupData !== undefined && !props?.isEditable) ? props?.groupData : null)
    const [postData, setPostData] = useState(null)
    const [myGroups, setMyGroups] = useState([])
    const [showPubliFeed, setShowPubliFeed] = useState(false)
    const [regionalFeed, setShowRegionalFeed] = useState(null)




    const onCardClick = (data) => {
        setGroupData(data)
        setDisplayGroup(false)
    }

    const onEditGrpClick = (data) => {
        setPostData(data)
        setDisplayGroup(true)

    }

    useEffect(() => {
        getPersonlaized()
    }, [])

   const getPersonlaized=async() =>{
    let result=null
        await getProfile(props.token).then((res) => {
           result=res.data.OK?.LCU_IsPublicPersonalize
       fetchMyGroups(result)

        },
        )
      }


    const fetchMyGroups = (regionalActive) => {
        getMyGroups(props.token).then((res) => {
            if (res.data.OK) {
                let data = res.data.grp;
                if (res.data.show_regional == 'y') {
                    let modifiedGroups = [{
                        "id": 0,
                        "name": "Regional Feed",
                        "userid": "public",
                        "admin": "1",
                        "superAdmin": "public",
                        "userpic": locciIcon,
                        "allow_post": "1",
                        "promo": "public",
                        "UCM_IsPersonalized": regionalActive,
                        "community": "public",
                        "created": "2023-02-17 08:30:17"

                    },
                    ...data

                    ]
                    modifiedGroups.flat()
                    setMyGroups([...modifiedGroups]);
                } else {
                    setMyGroups([...data]);
                }
            }
        });
    };

console.log('cond',!props.isEditable , displayGroup , !showEditPost);

    return (
        <>

            {props.location.pathname !== "/public-feed" ? <>

                {(!props.isEditable && displayGroup && !showEditPost) ?
                    <MyGroupModal   {...props} data={myGroups} closeModal={() => props.stopEdit()} onCardClick={(data) => onCardClick(data)} /> :
                    showEditPost ? <EditPost  {...props} groupId={groupData && groupData?.id ? groupData?.id : (groupData && groupData.id === 0 ? 0 : props.groupId)} groupData={!props?.isEditing ? groupData : null} onEditGrpClick={(data) => {
                        setshowEditPost(false)
                        onEditGrpClick(data)
                    }} postData={postData} /> :
                        <EditPost  {...props} groupId={groupData && groupData?.id ? groupData?.id : (groupData && groupData.id === 0 ? 0 : props.groupId)} groupData={!props?.isEditing ? groupData : null}
                            onEditGrpClick={(data) =>

                                onEditGrpClick(data)} postData={postData} />
                }
            </> :
                <>
                    {showPubliFeed ? <MyGroupModal   {...props} data={myGroups} closeModal={() => props.stopEdit()} onCardClick={(data) => {
                        onCardClick(data)
                        setShowPubliFeed(false)
                    }} />

                        : <EditPost  {...props} groupId={groupData && groupData?.id ? groupData?.id : (groupData && groupData.id === 0 ? 0 : props.groupId)} groupData={!props?.isEditing ? groupData : null} onEditGrpClick={(data) => {
                            setShowPubliFeed(true)
                            onEditGrpClick(data)
                        }} postData={postData} />
                    }
                </>

            }

        </>
    )
}

export default EditPostContainer
